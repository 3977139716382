import React from 'react';
import Router from './routes';
import GlobalStyles from './styles/globalStyles';

const App = () => {
  return(
    <>
      <Router/>
      <GlobalStyles/>
    </>
  )
}

export default App; 