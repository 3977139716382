import styled from 'styled-components';
import background from '../../../assets/images/trigger.png';
import PerfectScrollBar from 'react-perfect-scrollbar';

export const Container = styled(PerfectScrollBar)`
    margin: 0;
    padding: 0;
    height: 92vh;
    width: 100vw;
    .header 
    {
        background-image: linear-gradient(to bottom, rgba(28, 28, 28, 1), rgba(0, 33, 71, 1), rgba(0, 33, 71, 0.9));
        background-size: 100%;
        background-repeat: no-repeat;
        width: 100vw;
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;
        padding: 30px;
        box-shadow: 1px 3px 5px rgba(0,0,0,0.2);
    }

    .title-header
    {
        color: #fff;
        font-size: 17pt;
        font-weight: bold;
        margin: 0;
        margin-right: 5px;
        margin-left: 5px;
    }
    .subtitle-header
    {
        color: #fff;
        font-size: 17pt;
        font-weight: bold;
        margin: 0;
    }

    .title
    {
        color: #fff;
        font-size: 26pt;
        font-weight: bold;
        margin: 0;
        margin-top: -10px;
    }
    .subtitle
    {
        color: #fff;
        font-size: 26pt;
        font-weight: bold;
        margin: 0;
        margin-top: -10px;
    }
    .card-notify
    {
        padding: 10px;
        background: rgba(255,255,255, 0.8);
        border-radius: 60px;
        box-shadow: 1px 3px 5px rgba(0,0,0,0.3);
        margin-top: 10px;
    }
    .icon-notify
    {
        align-self: center;
        font-size: 25pt;
    }
    .content
    {
        align-self: center;
        font-size: 10pt;
        margin-left: 10px;
    }

    .icon-key
    {
        transform: rotate(90deg);
        color: #004d93;
        margin-left: 32px;
        margin-top: 15px;
        align-self: center;
        font-size: 13pt
    }
    .title-key
    {
        color: rgba(0,77,147,0.7);
        margin: 0;
        margin-top: 15px;
        font-weight: 600;
        align-self: center;
        font-size: 12pt;
    }
`;

export const Logo = styled.img`
    height: 70px;
    align-self: center;
    margin-bottom: 30px;
`;

export const Info = styled.div`
    position: fixed;
    bottom: 10px;
    left: 90px;
    width: 20%;
    transform: translateX(-50%);
    .icon-help
    {
        height: 50px;
        align-self: center;
        margin-left: -39px;
    }
`;


export const FaceAdd = styled.div`
    position: fixed;
    bottom: 15px;
    left: 75%;
    width: 200px;
    transform: translateX(-50%);
    .title-face
    {
        margin-right: 10px;
        font-size: 12pt;
        color: #004d93;
        font-weight: bold;
        align-self: center;
    }
    .icon-help
    {
        height: 50px;
        align-self: center;
        margin-left: -39px;
    }
`;
