import styled from 'styled-components';

export const Container = styled.div`
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    .fade-enter{
        opacity: 0;
    }
    .fade-exit{
        opacity: 1;
    }
    .fade-enter-active{
        opacity: 1;
    }
    .fade-exit-active{
        opacity: 0;
    }
    .fade-enter-active,
    .fade-exit-active{
        transition: opacity 500ms;
    }
    img {
        width: 90%;

    }
`;