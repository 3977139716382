import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { Logo, Container, Info, FaceAdd } from './styles/page.style';
import { MdOutlineEditNote, MdOutlineNotificationsActive } from 'react-icons/md';
import { TbFaceId } from "react-icons/tb";
import logo from '../../assets/images/logo.png';
import help from '../../assets/images/help.png';
import { Button, ButtonToolbar, IconButton, Tooltip, Whisper } from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import Trigger from '../../components/trigger';
import { StoresContext } from '../../stores';
import ModalInfoGeoLocation from '../../components/modal_info_geolocation';
import ModalInfoTuto from '../../components/modal_info_tuto';
import ModalExpired from '../../components/modal_expired';
import Modal_add_face from '../../components/modal_add_face';

const PageTriggering = () => {

  const { authorizationStore, visitorStore, getGeoLocationStore, triggerStore } = useContext(StoresContext);

  const [open, setOpen] = useState(false);
  const [face, setFace] = useState(false);

  const toggleOpen = () => setOpen(!open);
  const toggleFace = () => setFace(!face);

  useEffect(() => {
    if (authorizationStore.validated && localStorage.getItem('@token_authorization'))
      visitorStore.getByAutorizationId(localStorage.getItem('@token_authorization') ?? "");
  }, [authorizationStore.validated]);

  useEffect(() => {
    if (authorizationStore.validated)
      getGeoLocationStore.getGeoLocation();
  }, [authorizationStore.validated]);

  return (
    <Container>
      <div className='header'>
        <div className='d-flex justify-content-center'>
          <Logo src={logo} />
        </div>
        <div className='d-flex'>
          <p className='title-header'>Olá,</p>
          <p className='subtitle-header'>{authorizationStore.visitor?.nome.split(' ')[0]}</p>
        </div>

        <div className='d-flex justify-content-start card-notify'>
          <div className='align-self-center'>
            <MdOutlineEditNote className='icon-notify' />
          </div>
          <p className='content'>{authorizationStore.visitor?.recado === "" ? "Sem recado" : authorizationStore.visitor?.recado}</p>
        </div>
      </div>

      <Trigger />

      {
        authorizationStore.visitor?.facial &&
        <FaceAdd onClick={toggleFace}>
           <div className='d-flex'>
           <p className='title-face'>Cadastro de facial</p>
           <IconButton icon={<TbFaceId style={{ fontSize: 25, color: '#FFF' }} />} style={{ background: 'rgba(0,33,71, 0.8)', borderRadius: 60 }} appearance="primary" circle />
           </div>
        </FaceAdd>
      }


      {
        triggerStore.expired ? ''
          :
          <Info onClick={toggleOpen}>
              <img src={help} className='icon-help' style={{ marginBottom: '-8px' }} />
              <IconButton icon={<HelpOutlineIcon style={{ fontSize: 25, color: '#FFF' }} />} style={{ background: 'rgba(0,33,71, 0.8)', borderRadius: 60 }} appearance="primary" circle />
          </Info>
      }

      {
        !getGeoLocationStore.accepted
          ?
          <ModalInfoGeoLocation show={!getGeoLocationStore.accepted} hide={() => { }} />
          :
          ''
      }

      <ModalInfoTuto show={open} hide={toggleOpen} />
      <Modal_add_face show={face} hide={toggleFace} />
      <ModalExpired show={triggerStore.expired} hide={() => { }} />
    </Container>
  )
}

export default observer(PageTriggering);