import { runInAction, makeAutoObservable } from "mobx";
import { IAuthorization, IVisitor } from "../interfaces/IVisitor";
import { authentication } from "../services/authentication/api";

class AuthorizationStore {

    loading: boolean = false;
    error: boolean = false;
    validated: boolean = false;
    visitor: IAuthorization | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    validateAuthorizedById = async (authrozationId: string) => {
        this.loading = true;
        try
        {
            if(authrozationId)
            {
                const { data } = await authentication(authrozationId);
                if(data)
                {
                    data.acionamentosList = JSON.parse(data.acionamentos);
                    this.visitor = data;
                    setTimeout(() =>
                    {
                        this.loading = false;
                    }, 5000)
                    this.loading = false;
                    this.validated = true;
                    localStorage.setItem('@token_authorization', authrozationId);
                    localStorage.setItem('@token', data.token);
                    if(data.tokenMobile) localStorage.setItem('@token_mobile', data.tokenMobile);
                }
                else
                {
                    this.validated = false;
                }
            }
            this.loading = false;
        }
        catch(error)
        {
            this.loading = false;
            this.error = true;
        }
    }
}

export default AuthorizationStore;