import { observer } from 'mobx-react-lite';
import React, { useRef, useState, useContext } from 'react'
import { useSpring, animated } from "react-spring";
import { Acionamento, Container, Title } from './styles/component.style';
import { BsCheck2, BsCheck2All, BsChevronDoubleRight, BsDoorOpen, BsKey } from 'react-icons/bs';
import { Loader } from 'rsuite';
import { EStatus } from '../../interfaces/enums/EStatus';
import { MdErrorOutline } from 'react-icons/md';
import toast from 'react-hot-toast';
import { StoresContext } from '../../stores';
import { useTrigger } from '../../hooks/trigger.hook';
import { ITragger } from '../../interfaces/ITrigger';
import NotFound from '../not_found';
import { CgKey } from "react-icons/cg";

const Trigger = () => {

  const { triggerStore, visitorStore, getGeoLocationStore, authorizationStore } = useContext(StoresContext);
  const { acionar, loading } = useTrigger();
  const [isPressed, setIsPressed] = useState(false);
  const [isPressedButton, setIsPressedButton] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState<EStatus>(EStatus.default);

  const containerRef = useRef<HTMLDivElement>(null);
  const containerTrigger = useRef<HTMLDivElement>(null);
  const buttonWidth = 70;

  const [{ x }, set] = useSpring<{ x: number }>(() => ({
    x: 0,
    config: { mass: 0.1, tension: 200, friction: 10 },
    onFrame: ({ x }: { x: number }) => {
      set({ x });
    },
    onRest: (finalPosition) => {
      const containerWidth = containerTrigger.current?.offsetWidth ?? 1 - buttonWidth
      const valueEnd = finalPosition.value.x as number + buttonWidth;

      if(valueEnd < containerWidth)
      {
        set({ x : 0 });
      }
      else
      {
        set({ x : finalPosition.value.x as number });
      }
    }
  }));

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setIsPressed(true);
    handleTouchMove(e);
  };

  const handleTouchEnd = () => {
    setIsPressed(false);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!isPressed && !disabled) {
      return;
    }

    const buttonWidth = 70;
    const parentWidth = (e.currentTarget as HTMLDivElement).offsetWidth;
    const parentWidth2 = (e.currentTarget as HTMLDivElement).offsetWidth - buttonWidth;
    const newX =
    e.touches[0].clientX -
    (e.currentTarget as HTMLDivElement).offsetLeft -
    buttonWidth / 2;

    set({ x: newX });
    if (newX < 0) {
      set({ x: 0 });
    }
    if (
      newX > (parentWidth - buttonWidth) && 
      !disabled && triggerStore.selecionado && 
      authorizationStore.visitor &&
      getGeoLocationStore.accepted &&
      getGeoLocationStore.latitude !== 0 &&
      getGeoLocationStore.longitude !== 0
    ) {
      setDisabled(true);
      setIsPressed(false);
      set({ x: parentWidth2 });
      setStatus(EStatus.loadingTriggerTrue)
      var trigger = 
      {
        acionamentoId: triggerStore.selecionado,
        visitanteId: authorizationStore.visitor.visitanteId,
        latitude: getGeoLocationStore.latitude,
        longitude: getGeoLocationStore.longitude
      }
      acionar(trigger)
      .then((res) =>
      {
        if(res === 'sucesso') setStatus(EStatus.success) 
        else setStatus(EStatus.error)
        setTimeout(() =>
        {
          setStatus(EStatus.default)
          set({ x: 0 });
          setDisabled(false);
        }, 2000);  
      })
      .catch(() =>
      {
        setStatus(EStatus.error);
        set({ x: 0 });
        setStatus(EStatus.loadingTriggerFalse)
        setDisabled(false);
      })
    }
    else  if(newX < parentWidth2 && disabled)
    {
      set({ x: 0 });
    }
    else if (newX > parentWidth - buttonWidth) {
      set({ x: parentWidth - buttonWidth });
    }
  };

  
  if(visitorStore.visitor?.acionamentosList.length === 0)
  {
    return <NotFound message='Nenhum acionamento encontrado' />
  }

  const renderAction = (action: EStatus, select?: string) =>
  {
    switch(action)
    {
      case EStatus.success:
        return triggerStore.selecionado === select ? <BsCheck2All className='icon-success'/> : <BsChevronDoubleRight className='icon-right' />;
      case EStatus.error:
        return triggerStore.selecionado === select ? <MdErrorOutline className='icon-error'/> : <BsChevronDoubleRight className='icon-right' />;
      case EStatus.loadingTriggerTrue:
        return triggerStore.selecionado === select ? <Loader className='loader' size={'lg'} /> : <BsChevronDoubleRight className='icon-right' />;
      case EStatus.loadingTriggerFalse:
        return <BsChevronDoubleRight className='icon-right' />;
      case EStatus.default:
        return <BsChevronDoubleRight className='icon-right' />;
    }

    return <div>Erro...</div>;
  }
  return (
    <div className=''>
      {
        authorizationStore.visitor?.acionamentosList.map((trigger, i) => (
          <div key={i} className='mt-2' style={{pointerEvents: disabled ? 'none' : 'auto'}} onTouchStartCapture={() => 
          {
            if(!disabled)
              triggerStore.definirSelecionado(trigger.Id)
            else
              set({ x: x })
          }}>
            <Acionamento className='d-flex mt-4'>
              <BsDoorOpen className='icon'/>
              <div>
                <p className='title'>{trigger.Nome}</p>
                <p className='title'>Quantidade: {authorizationStore.visitor?.quantidade}</p>
              </div>
            </Acionamento>
            <Container
              ref={containerTrigger}
              
              onTouchMove={!disabled || !loading ? handleTouchMove : undefined}
              className='d-flex justify-content-between'
            >
              <animated.div
                style={{
                  transform: triggerStore.selecionado === trigger.Id ? x.to((x) => `translateX(${x}px)`) : `translateX(0px)`,
                  height: "100%",
                  width: "70px",
                  backgroundColor: "#fff",
                  cursor: "pointer",
                  borderRadius: 60,
                  border: '2px solid rgba(0,33,71, 0.5)',
                }}
                onTouchStartCapture={(res) =>{
                  setIsPressedButton(true);
                  triggerStore.isPressed = true;
                }
                }
                onTouchEndCapture={() =>
                {
                  setIsPressedButton(false);
                  triggerStore.isPressed = false;
                }}
                onTouchStart={!disabled || !loading ? handleTouchStart : undefined}
              onTouchEnd={!disabled || !loading ? handleTouchEnd : undefined}
                className={'d-flex justify-content-center'}
              >
                {
                  renderAction(status, trigger.Id)
                }
              </animated.div>
              <div className='d-flex align-self-center'>
                <BsChevronDoubleRight style={{color: '#fff', fontSize: 20, marginLeft: '-7px'}}/>
                <BsChevronDoubleRight style={{color: '#fff', fontSize: 20, marginLeft: '-7px'}}/>
                <BsChevronDoubleRight style={{color: '#fff', fontSize: 20, marginLeft: '-7px'}}/>
              </div>
              <div className='div-check d-flex justify-content-center'>
                <BsCheck2 className='icon-check'/>
              </div>
            </Container>
            <Title>DESLIZE PARA A DIREITA</Title>
          </div>
        ))
      }
    </div>
  )
}

export default observer(Trigger);
