import { makeAutoObservable } from "mobx";

class TriggerStore {

    selecionado: string = '';
    loading: boolean = false;
    expired: boolean = false;
    isPressed: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async definirSelecionado(acionamentoId: string) {
        if(acionamentoId)
            this.selecionado = acionamentoId;
        else
            this.selecionado = '';
    }
}

export default TriggerStore;