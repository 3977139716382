import { makeAutoObservable, runInAction } from 'mobx';
import { AxiosError } from 'axios';
import { IResponseCollection } from '../interfaces/IResponseCollection';

class ResponseCollectionStore {
  response: IResponseCollection | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setSelected(response: IResponseCollection) {
    if (response) this.response = response;
  }
}

export default ResponseCollectionStore;