import { observer } from 'mobx-react-lite';
import React, { useRef, useState, useContext, FC } from 'react'
import { Modal } from 'rsuite';
import background from '../../assets/images/location.png';
import { Container } from './styles/component.style';
import { StoresContext } from '../../stores';

interface Props
{
  show: boolean;
  hide: () => void;
}

const ModalInfoGeoLocation: FC<Props> = ({ show, hide }) => {

  const { getGeoLocationStore } = useContext(StoresContext);

  const RenderAccepetedLocation = () =>
  {
    return(
      <Container>
        <div className='d-flex justify-content-center'>
          <img src={background} className='img'/>
        </div>
        <p className='title'>Habilite a localização</p>
        <p className='subtitle'>Para a devida liberação do acionamento, é necessário que a funcionalidade de localização esteja ativada</p>
        <p className='info'>A liberação dos acionamentos ocorrerá imediatamente após a habilitação da sua localização.</p>
      </Container>
    )
  }

  const RenderNotAccepetedLocation = () =>
  {
    return(
      <Container>
        <div className='d-flex justify-content-center'>
          <img src={background} className='img'/>
        </div>
        <p className='title'>Ops!</p>
        <p className='subtitle'>Infelizmente, constatamos que a funcionalidade de localização está desabilitada, o que pode prejudicar a experiência do usuário :(</p>
        <p className='info'>
          Para que os acionamentos possam estar disponíveis para você, é necessário acessar as configurações e habilitar a funcionalidade de localização. <br/>
          Em seguida, a atualização da página é necessária para que as alterações sejam feitas com sucesso
          </p>
      </Container>
    )
  }

  return (
    <Modal open={show}>
      <Modal.Body>
          {!getGeoLocationStore.accepted && getGeoLocationStore.message !== 'prompt' ? <RenderNotAccepetedLocation/> : <RenderAccepetedLocation/>}
      </Modal.Body>
    </Modal>
  )
}

export default observer(ModalInfoGeoLocation);
