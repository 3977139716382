import { runInAction, makeAutoObservable } from "mobx";
import { IAuthorization, IVisitor } from "../interfaces/IVisitor";
import { authentication } from "../services/authentication/api";
import VisitorAPI from "../apis/VisitorAPI";

class VisitorStore {

    loading: boolean = false;
    visitor: IAuthorization | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    getByAutorizationId = async (authenticationId: string) => {
        this.loading = true;
        try
        {
            if(authenticationId)
            {
                const { data } = await VisitorAPI.getByAutorizationId(authenticationId);
                if(data)
                {
                    data.acionamentosList = JSON.parse(data.acionamentos);
                    this.visitor = data;

                    setTimeout(() =>
                    {
                        this.loading = false;
                    }, 5000)
                }
            }
            this.loading = false;
        }
        catch(error)
        {
            this.loading = false;
        }
    }

}

export default VisitorStore;