import { useContext, useState } from "react";
import axios from "axios";
import { StoresContext } from "../stores";
import TriggeringAPI from "../apis/TriggerAPI";
import { ITragger } from "../interfaces/ITrigger";
import { toast } from "react-hot-toast";

export const useTrigger = () => {
    
    const { triggerStore, visitorStore, authorizationStore } = useContext(StoresContext);
    const [loading, setLoading] = useState(false);

    const acionar = async (acionamento: ITragger) => {
        setLoading(true);
            try {
            const { data, status } = await TriggeringAPI.acionar(acionamento);
            if(status === 200) {
                toast.success('Acionamento acatado com sucesso!');
                if(visitorStore.visitor?.acionamentos.includes("Quantidade"))
                {
                    if(authorizationStore.visitor && authorizationStore.visitor.quantidade >= 1)
                    {
                        if(authorizationStore.visitor.quantidade)
                        {
                            authorizationStore.visitor.quantidade = authorizationStore.visitor.quantidade - 1;

                            var quantidade = authorizationStore.visitor.quantidade;
                            if(quantidade === 0) {
                                triggerStore.expired = true;
                                setLoading(false);
                                return 'sucesso'
                            }
                        }
                    }
                    else alert("Erro interno: Sem visitante selecionado");
                }
                else
                {
                    if(authorizationStore.visitor)
                    {
                        if(authorizationStore.visitor.quantidade)
                        {
                            authorizationStore.visitor.quantidade = authorizationStore.visitor?.quantidade - 1;
                        }
                        if(data.mensagem === '0') {
                            triggerStore.expired = true;
                            setLoading(false);
                            return 'sucesso'
                        }
                    }
                }
                setLoading(false);
                return 'sucesso'
            }
            else if(status === 204) {
                toast.error('Erro ao fazer o acionamento, por favor tente novamente');
                setLoading(false);
            }
            setLoading(false);
        } catch(e) {
            if(axios.isAxiosError(e))
            {
                toast.error(e.response?.data.mensagem);
            }
            setLoading(false);
        }
    }

    return {
        acionar,
        loading
    }

}