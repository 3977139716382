import { observer } from 'mobx-react-lite';
import React, { useRef, useState, useContext, FC, useEffect } from 'react';
import { Button, Modal } from 'rsuite';
import { BsCamera, BsX } from 'react-icons/bs';
import { StoresContext } from '../../stores';
import { CardLoad, CardTemFacial, Container } from './styles/component.style';
import { useFace } from '../../hooks/face.hook';
import face from '../../assets/gifs/face.gif';

interface Props {
  show: boolean;
  hide: () => void;
}

const ModalAddFace: FC<Props> = ({ show, hide }) => {
  const { authorizationStore, responseCollectionStore } = useContext(StoresContext);
  const { add, remove, loading, deleting } = useFace();
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [photoBase64, setPhotoBase64] = useState<string | null>(null);
  const [faceInPosition, setFaceInPosition] = useState<boolean>(false);

  useEffect(() => {
    if (show && window.location.pathname.split('/')[3] !== null) {
      authorizationStore.validateAuthorizedById(window.location.pathname.split('/')[3]);
      setPhotoBase64(null);
    }
    else {
      stopCamera();
    }
  }, [show])

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Erro ao acessar a câmera:', error);
    }
  };

  const dataURLToBlob = (dataURL: string): Blob => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const capturePhoto = () => {
    if (videoRef.current) {
      const video = videoRef.current;

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      if (context) {
        // Define o tamanho do canvas para 300x300
        canvas.width = 300;
        canvas.height = 300;

        // Desenha a imagem do vídeo no canvas redimensionado
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Tenta gerar a imagem em formato JPEG com qualidade reduzida
        let quality = 0.8; // Qualidade inicial
        let dataURL = canvas.toDataURL('image/jpg', quality);
        let blob = dataURLToBlob(dataURL);

        // Ajusta a qualidade até que a imagem esteja abaixo de 30 KB
        while (blob.size > 30 * 1024 && quality > 0.1) {
          quality -= 0.1;
          dataURL = canvas.toDataURL('image/jpg', quality);
          blob = dataURLToBlob(dataURL);
        }

        setPhotoBase64(dataURL);
      }
    }
  };

  const removePhoto = () => {
    setPhotoBase64(null);
    startCamera();
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;
      stream.getTracks().forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  useEffect(() => {
    if (window.location.pathname.split('/')[3] !== null) {
      var tokenId = window.location.pathname.split('/')[3];
      if (tokenId)
        authorizationStore.validateAuthorizedById(tokenId)
    }
  }, []);

  const checkFacePosition = () => {
    setFaceInPosition(true);
  };

  const handleFace = () => {
    const base64Data = photoBase64?.replace(/^data:image\/[a-zA-Z]+;base64,/, '');
    if (base64Data && window.location.pathname.split('/')[3] !== null) {
      var chaveVirtualId = window.location.pathname.split('/')[3];
      add({
        chaveVirtualId: chaveVirtualId,
        foto: base64Data
      });
    }
  }

  const handleRemove = () => {
    if (window.location.pathname.split('/')[3] !== null) {
      var chaveVirtualId = window.location.pathname.split('/')[3];
      remove(chaveVirtualId);
    }
  }

  useEffect(() => {
    if (show) {
      startCamera();
      const intervalId = setInterval(checkFacePosition, 100);
      return () => {
        clearInterval(intervalId);
        stopCamera();
      };
    }
  }, [show]);




  const renderMain = () => {

    if (deleting) {
      return (
        <CardLoad className='d-flex justify-content-center mt-5'>
          <div>
            <p className='title'>Por favor, aguarde enquanto removemos sua face e sincronizamos com os equipamentos...</p>
            <div className='d-flex justify-content-center'>
              <img src={face} className='img' />
            </div>
          </div>
        </CardLoad>
      )
    }

    if (authorizationStore.visitor?.temFacial)
      return (
        <CardTemFacial>
          <div className='mt-2'>
            <p className='title'>Você já tem facial cadastrado</p>
            <p className='subtitle'>Ao chegar no condomínio, seu acesso será automaticamente liberado.</p>
            <Button appearance='primary' color='red' style={{ width: '100%', marginTop: 30 }} onClick={handleRemove} disabled={deleting} loading={deleting}>Excluir facial</Button>
          </div>
        </CardTemFacial>)

    if (loading) {
      return (
        <CardLoad className='d-flex justify-content-center mt-5'>
          <div>
            <p className='title'>Por favor, aguarde enquanto validamos sua face e sincronizamos com os equipamentos...</p>
            <div className='d-flex justify-content-center'>
              <img src={face} className='img' />
            </div>
          </div>
        </CardLoad>
      )
    }

    if (responseCollectionStore.response) {
      return (
        <CardLoad className='mt-5'>
          <div className='d-flex justify-content-center'>
            <div>
              <div>
                {
                  responseCollectionStore.response.acertos.length > 0 && (
                    <>
                      <p className='title-response'>Acertos</p>
                      <>
                        {responseCollectionStore.response.acertos.map((x, i) => (
                          <div key={i} className='mt-2'>
                            <p className='title-collection'>Facial cadastrado com sucesso</p>
                            <p className='subtitle-collection'>{x.local} - {x.notificacao}</p>
                          </div>
                        ))}
                      </>
                    </>
                  )
                }

                {
                  responseCollectionStore.response.erros.length > 0 && (
                    <>
                      <p className='title-response mt-3'>Erros</p>
                      {
                        responseCollectionStore.response.erros.map((x, i) =>
                          <div key={i} className='mt-2'>
                            <p className='title-collection'>Erro ao coletar facial</p>
                            <p className='subtitle-collection'>{x.local} - {x.notificacao}</p>
                          </div>
                        )
                      }
                    </>
                  )
                }

              </div>
              <div>

              </div>
            </div>
          </div>
          <Button className='mt-5' appearance='primary' onClick={() => {
            if (responseCollectionStore.response && responseCollectionStore.response.acertos.length > 0) {
              responseCollectionStore.response = null;
              hide();
            }
            else
              responseCollectionStore.response = null;

          }
          } style={{ width: '100%', background: '#004d93' }}>
            {
              responseCollectionStore.response.acertos.length > 0 ? 'Fechar' : 'Voltar'
            }
          </Button>
        </CardLoad>
      )
    }

    return (
      <Container>
        <div>
          {!photoBase64 ? (
            <div style={{ position: 'relative' }}>
              <video ref={videoRef} autoPlay style={{ width: '100%', marginTop: 50 }} className='picture' />
              {/* Desenho do formato do rosto */}
              <div style={{

                position: 'absolute',
                top: '60%',
                left: '50%',
                width: '40%',
                height: '60%',
                transform: 'translate(-50%, -50%)',
                border: faceInPosition ? '3px solid #00FF00' : '3px solid #FFFFFF',
                borderRadius: '50% 50% 40% 40%',
                boxSizing: 'border-box',
                zIndex: 2,
              }}>
                {/* Fundo preto transparente */}
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  borderRadius: '50% 50% 40% 40%',
                  zIndex: 1,
                }} />
                {/* Linha central vertical */}
                <div style={{
                  position: 'absolute',
                  top: '0%',
                  left: '50%',
                  width: '2px',
                  height: '100%',
                  backgroundColor: faceInPosition ? '#00FF00' : '#FFFFFF',
                  transform: 'translateX(-50%)',
                  zIndex: 3,
                }} />
                {/* Linha central horizontal */}
                <div style={{
                  position: 'absolute',
                  top: '50%',
                  left: '0%',
                  width: '100%',
                  height: '2px',
                  backgroundColor: faceInPosition ? '#00FF00' : '#FFFFFF',
                  transform: 'translateY(-50%)',
                  zIndex: 3,
                }} />
              </div>
            </div>
          ) : (
            <div className="mt-3">
              <p className='title-response mt-5'>Foto Capturada:</p>
              <img src={photoBase64} alt="Foto Capturada" style={{ width: '100%', marginTop: 20 }} className='picture' />
            </div>
          )}
          <canvas ref={canvasRef} style={{ display: 'none' }} />
          <div className="d-flex justify-content-center mt-3">
            {!photoBase64 ? (
              <div>
                <div className='d-flex justify-content-center m-2'>
                  <Button startIcon={<BsCamera size={22} />} appearance='primary' color='green' onClick={capturePhoto} className="ml-3" disabled={!faceInPosition}>Capturar imagem</Button>
                </div>
                <p className='info-text'>- Certifique-se de que o ambiente esteja bem iluminado, evitando sombras sobre o rosto que possam interferir na captura.</p>
                <p className='info-text'>- Posicione-se de frente para a câmera, garantindo que seu rosto esteja centralizado no quadro e em uma distância apropriada para uma captura clara e nítida.</p>
                <p className='info-text'>- Mantenha uma expressão natural e relaxada, evitando expressões faciais extremas que possam distorcer a detecção do rosto pelo equipamento.</p>
              </div>
            ) : (
              <>
                <Button appearance='primary' color='red' onClick={removePhoto} className="m-1">Tirar outra foto</Button>
                <Button appearance='primary' color='green' onClick={handleFace} className="m-1">Salvar</Button>
              </>
            )}
          </div>
        </div>
      </Container>
    )
  }

  return (
    <Modal open={show} onClose={hide} className="mt-5" onExited={stopCamera} style={{ marginTop: 100 }}>
      <Modal.Body style={{ marginTop: '-10px', padding: 5 }}>
        <div className="d-flex justify-content-end" style={{ marginBottom: '-50px' }}>
          <BsX style={{ fontSize: 35, color: 'red', zIndex: 1 }} onClick={hide} />
        </div>
        {renderMain()}
      </Modal.Body>
    </Modal>
  );
};

export default observer(ModalAddFace);