import React from 'react';
import { observer } from 'mobx-react-lite';
import { CardBottom, Container } from './styles/page.styled';
import { Loader } from 'rsuite';
import backgroundgif from '../../assets/gifs/background.gif'
import logo from '../../assets/images/logo.png'

const PageLoading = () => {
  return (
    <Container>
        <div className='d-flex justify-content-center'>
          <img src={backgroundgif} className='gif' />
        </div>

      <CardBottom>
        <div>
          <p className='title'>Essa é sua chave virtual!</p>
          <p className='subtitle'>Aguarde um instante. <br/> Estamos validando seu acesso...</p>
        </div>

        <div className='d-flex justify-content-between mt-5'>
          <div className='align-self-center'>
            <img src={logo} className='logo' />
          </div>
          <div className='align-self-center d-flex justify-content-center card-icon'>
            <Loader size='lg' className='icon-load' />
          </div>
        </div>
      </CardBottom>
    </Container>
  )
}

export default observer(PageLoading);